/**
 * ko-KR과 같은 문자를 대문자로 바꾸고 - 앞까지 끊어서 리턴한다.
 * @param {*} str 
 * @returns console.log(getUppercasePrefix("ko-KR")); // "KO"
console.log(getUppercasePrefix("en-US")); // "EN"
console.log(getUppercasePrefix("ja-JP")); // "JA"
 */
export const getUppercasePrefix = (str) => {
  if (!str) return "";
  const [prefix] = str.toUpperCase().split("-");
  return prefix;
};
