import "@/style/reset.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import axios from "axios";
import { createI18n } from "vue-i18n";
import lang from "@/locales/index";
import { getUppercasePrefix } from "./shared/util/getUppercasePrefix";

const app = createApp(App);

const user = JSON.parse(sessionStorage.getItem("user"));
if (user) {
  store.dispatch("login", user);
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.user.id) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export const i18n = createI18n({
  legacy: false,
  // locale: "KO",
  locale:
    localStorage.getItem("lang") || getUppercasePrefix(navigator.language),
  fallbackLocale: "EN",
  messages: lang,
});

app.use(i18n);
app.use(router);
app.use(store);
app.config.globalProperties.$axios = axios;
app.mount("#app");
