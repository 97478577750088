import io from 'socket.io-client';

class PCDSocket{
  constructor(udpPort){
    this.socket = io(`wss://www.sivion-aj.com/point_cloud`);
    this.udpPort = udpPort;

    this.promise = new Promise((resolve)=>{
      this.socket.on('connect', ()=>{
        console.log(`UDP Port : ${udpPort}의 UDP 연결되었습니다.`);
        resolve();
      });
    })
    window.onbeforeunload = ()=>{
      if(this.socket){
        console.log(`UDP Port : ${udpPort}의 UDP 연결이 해제되었습니다.`);
        this.socket.disconnect();
      }
    }
  }
  async pointCloudData(callback){
    await this.promise;
    this.socket.on(`${this.udpPort}/point_cloud_data`, function(data){
      callback(data);
    });
  }
  async pointCloudPose(callback){
    await this.promise;
    this.socket.on(`${this.udpPort}/pose`, function(data){
      callback(data);
    });
  }
  closeSocket(){
    console.log(`UDP Port : ${this.udpPort}의 UDP 연결이 해제되었습니다.`)
    this.socket.disconnect();
  }
}

export default PCDSocket