<template>
  <div class="report_editor">
    <div class="report_editor_left_wrap">
      <div class="report_editor_img_wrap">
        <div class="report_editor_alert input" v-if="!selectedSegmentation">
          {{ $t("albumAlert.unanalyzedPhoto") }}
        </div>
        <div class="report_editor_canvas_wrap" ref="report_editor_canvas_wrap">
          <canvas
            class="report_editor_img"
            ref="report_editor_img"
            v-show="imageState"
          ></canvas>
          <canvas
            class="report_editor_result_img"
            ref="report_editor_result_img"
            v-show="!imageState"
          ></canvas>
        </div>
        <div
          class="report_editor_transform button"
          @click="changeImageState"
        ></div>
        <div class="report_editor_button_wrap" v-if="photoList">
          <div
            class="report_editor_button_first button"
            @click="clickPhotoChangeButton('first')"
          ></div>
          <div
            class="report_editor_button_prior button"
            @click="clickPhotoChangeButton('prior')"
          ></div>
          <div class="report_editor_page" v-if="selectedPhoto">
            <input
              class="report_edit_page_current input"
              :placeholder="getPhotoNumber"
              ref="page_current_input"
              @keydown.enter="changePhotoNumber"
            />
            /
            <div class="report_edit_page_total">{{ photoList.length }}</div>
          </div>
          <div
            class="report_editor_button_next button"
            @click="clickPhotoChangeButton('next')"
          ></div>
          <div
            class="report_editor_button_last button"
            @click="clickPhotoChangeButton('last')"
          ></div>
        </div>
      </div>
      <slot name="editor-info"></slot>
    </div>
    <slot name="editor-crack"></slot>
  </div>
  <slot name="editor-modal"></slot>
</template>

<script>
import Panzoom from "@panzoom/panzoom";

export default {
  emits: ["changeImageState", "clickPhoto"],
  computed: {
    getPhotoNumber() {
      let index = this.photoList.findIndex(
        (photo) => photo.photo.id == this.selectedPhoto.photo.id
      );
      return `${index + 1}`;
    },
  },
  props: {
    drawState: String,
    imageState: Boolean,
    photoList: Array,
    selectedPhoto: Object,
    selectedSegmentation: Object,
  },
  watch: {
    selectedPhoto() {
      if (this.panzoom) {
        this.panzoom.reset({ animate: false });
      }
    },
  },
  mounted() {
    this.panzoom = new Panzoom(this.$refs.report_editor_canvas_wrap, {
      contain: "outside",
      cursor: "default",
      minScale: 1,
      maxScale: 20,
    });
    this.$refs.report_editor_canvas_wrap.addEventListener(
      "wheel",
      this.panzoom.zoomWithWheel
    );
    this.$refs.report_editor_canvas_wrap.addEventListener(
      "contextmenu",
      (e) => {
        e.preventDefault();
      }
    );
  },
  methods: {
    setPan(boolean) {
      if (this.panzoom) {
        this.panzoom.setOptions({ disablePan: !boolean });
      }
    },
    changeImageState() {
      if (this.drawState) {
        this.$store.commit(
          "openAlert",
          `${this.$t("albumAlert.drawing")}\n${this.$t(
            "albumAlert.changeImageAfterDrawing"
          )}`
        );
      } else {
        this.$emit("changeImageState");
      }
    },
    clickPhotoChangeButton(button) {
      this.$refs.page_current_input.value = null;
      if (this.drawState) {
        this.$store.commit(
          "openAlert",
          `${this.$t("albumAlert.drawing")}\n${this.$t(
            "albumAlert.changeImageAfterDrawing"
          )}`
        );
      } else {
        let index = this.photoList.findIndex(
          (photo) => photo.id == this.selectedPhoto.id
        );
        let number;
        switch (button) {
          case "first":
            number = 0;
            break;
          case "prior":
            number = index - 1;
            break;
          case "next":
            number = index + 1;
            break;
          case "last":
            number = this.photoList.length - 1;
            break;
          default:
            break;
        }
        if (this.photoList[number]) {
          this.$emit("clickPhoto", this.photoList[number]);
        } else {
          this.$store.commit(
            "openAlert",
            this.$t("albumAlert.noPrevNextPhoto")
          );
        }
      }
    },
    changePhotoNumber(event) {
      if (this.drawState) {
        this.$store.commit(
          "openAlert",
          `${this.$t("albumAlert.drawing")}\n${this.$t(
            "albumAlert.changeImageAfterDrawing"
          )}`
        );
      } else {
        if (
          0 < event.target.value &&
          event.target.value <= this.photoList.length
        ) {
          this.$emit("clickPhoto", this.photoList[event.target.value - 1]);
        } else if (!event.target.value) {
          //Do nothing
        } else {
          this.$store.commit(
            "openAlert",
            `${this.$t("droneAlert.onlyAbleToEnterBetweenStartEndNumber", {
              startNumber: 1,
              endNumber: this.photoList.length,
            })}`
          );
        }
        event.target.value = null;
      }
    },
  },
};
</script>

<style lang="scss">
.report_editor {
  position: absolute;
  display: flex;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: black;
  z-index: 20;
}
.report_editor_left_wrap {
  width: calc(100% - 520px);
  height: 100%;
}
.report_editor_img_wrap {
  position: relative;
  width: 100%;
  height: calc(100% - 210px);
}
.report_editor_alert {
  position: fixed;
  left: calc(30% - 125px);
  top: calc(50% - 130px);
  @include flexbox;
  width: 250px;
  height: 50px;
  font-size: 1.4rem;
  z-index: 20;
}
.report_editor_canvas_wrap {
  width: 100%;
  height: 100%;
}
.report_editor_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.report_editor_result_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.report_editor_transform {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/toggle.svg");
  background-size: 20px 20px;
}
.report_editor_transform:hover {
  background-image: url("@/../public/images/toggle_hover.svg");
  background-size: 20px 20px;
}
.report_editor_button_wrap {
  position: absolute;
  bottom: 10px;
  left: 0px;
  @include flexbox;
  width: 100%;
  height: 30px;
}
.report_editor_button_first {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/leftDoubleArrow.svg");
  background-size: 20px 20px;
  margin-right: 5px;
}
.report_editor_button_first:hover {
  background-image: url("@/../public/images/leftDoubleArrow_hover.svg");
  background-size: 20px 20px;
}
.report_editor_button_prior {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/leftArrow.svg");
  background-size: 20px 20px;
}
.report_editor_button_prior:hover {
  background-image: url("@/../public/images/leftArrow_hover.svg");
  background-size: 20px 20px;
}
.report_editor_page {
  @include flexbox;
  width: 100px;
  height: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  color: $main_white;
}
.report_edit_page_current {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 45px;
  height: 30px;
  margin-right: 10px;
}
.report_edit_page_current::placeholder {
  text-align: center;
}
.report_edit_page_total {
  @include flexbox;
  width: 45px;
  height: 30px;
}
.report_editor_button_next {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/rightArrow.svg");
  background-size: 20px 20px;
}
.report_editor_button_next:hover {
  background-image: url("@/../public/images/rightArrow_hover.svg");
  background-size: 20px 20px;
}
.report_editor_button_last {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/rightDoubleArrow.svg");
  background-size: 20px 20px;
  margin-left: 5px;
}
.report_editor_button_last:hover {
  background-image: url("@/../public/images/rightDoubleArrow_hover.svg");
  background-size: 20px 20px;
}
</style>
